<style lang="less" scoped>
.idc {
  width: 100%;
  min-height: 100vh;
  background: #070911;
  padding-top: 90px;
  &-fifth {
    width: 100%;
    background: #0f1118;
    position: relative;
    color: rgba(222, 230, 255, 1);
    text-align: center;
    padding: 90px 0;
    &-title {
      font-size: 30px;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 4px;
      margin: 0;
    }
    &-subtitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 4px;
      margin: 0;
    }
    &-container {
      width: 76.7%;
      max-width: 1104px;
      margin: 50px auto;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // position: relative;
      &-item {
        // width: 250px;
        width: 22.6%;
        padding-bottom: 34px;
        margin-top: 34px;
        background: #070911;
        &-top {
          width: 70px;
          height: 70px;
          background: center / contain no-repeat url("../assets/idc_circle.png");
          position: relative;
          margin: 35px auto 16px;
          img {
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        p {
          margin: 0;
          font-family: "PingFangSC-Regular", "PingFang SC";
          color: rgba(222, 230, 255, 1);
          &:nth-child(2),
          &:nth-child(3) {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
          }
          &:nth-child(4) {
            margin-top: 16px;
            font-size: 10px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
  }
  .r-button-5 {
    margin: 77px auto 90px;
  }
}
</style>

<template>
  <div class="idc">
    <div class="idc-fifth">
      <div class="idc-fifth-title">
        {{$t('D44')}}
      </div>
      <div class="idc-fifth-subtitle">
        {{$t('D45')}}
      </div>
      <div class="idc-fifth-container">
        <div class="idc-fifth-container-item">
          <div class="idc-fifth-container-item-top">
            <img src="../assets/idc_Ram.png"
                 alt="">
          </div>
          <p>
            {{$t('H19')}}
          </p>
          <p>
            {{$t('H20')}}
          </p>
          <p>
            {{$t('H21')}} <br />
            {{$t('H22')}} <br />
            {{$t('H23')}} <br />
            {{$t('H24')}} <br />
            {{$t('H25')}} <br />
            {{$t('H26')}} <br />
            {{$t('H27')}} <br />
            {{$t('H28')}}
          </p>
        </div>
        <div class="idc-fifth-container-item">
          <div class="swiper-slide-item">
            <div class="idc-fifth-container-item-top">
              <img src="../assets/idc_liu.png"
                   alt="">
            </div>
            <p>
              {{$t('H29')}}
            </p>
            <p>
              {{$t('H30')}}
            </p>
            <p>
              {{$t('H31')}}<br />
              {{$t('H32')}}<br />
              {{$t('H33')}}<br />
              {{$t('H34')}}<br />
              {{$t('H35')}}<br />
              {{$t('H36')}}<br />
              {{$t('H37')}}<br />
              {{$t('H38')}}
            </p>
          </div>
        </div>
        <div class="idc-fifth-container-item">
          <div class="swiper-slide-item">
            <div class="idc-fifth-container-item-top">
              <img src="../assets/idc_li.png"
                   alt="">
            </div>
            <p>
              {{$t('H39')}}
            </p>
            <p>
              {{$t('H40')}}
            </p>
            <p>
              {{$t('H41')}}<br />
              {{$t('H42')}}<br />
              {{$t('H43')}}<br />
              {{$t('H44')}}<br />
              {{$t('H45')}}<br />
              {{$t('H46')}}<br />
              {{$t('H47')}}
            </p>
          </div>
        </div>
        <div class="idc-fifth-container-item">
          <div class="swiper-slide-item">
            <div class="idc-fifth-container-item-top">
              <img src="../assets/idc_eloi.png"
                   alt="">
            </div>
            <p>
              {{$t('H48')}}
            </p>
            <p>
              {{$t('H49')}}
            </p>
            <p>
              {{$t('H50')}} <br />
              {{$t('H51')}}<br />
              {{$t('H52')}}<br />
              {{$t('H53')}}<br />
              {{$t('H54')}}<br />
              {{$t('H55')}}<br />
              {{$t('H56')}}
            </p>
          </div>
        </div>
        <div class="idc-fifth-container-item"
             v-for="(item, index) in expertsList"
             :key="index">
          <div class="swiper-slide-item">
            <div class="idc-fifth-container-item-top">
              <img :src="'/api/static/'+item.avatar"
                   alt="">
            </div>
            <p>
              {{ item.name }}
            </p>
            <p>
              {{ item.title }}
            </p>
            <p v-html="item.description">
            </p>
          </div>
        </div>
      </div>
      <div class="r-button r-button-5"
           @click="toProduction(1)">
        {{$t('signUp')}}
      </div>
    </div>
    <Common-activies :style="{maxWidth: '1104px', margin: '0 auto'}"></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonActivies from '../components/common-activities-c';
import CommonFooter from '../components/common-footer';

export default {
  name: 'idc-master',
  data () {
    return {
      expertsList: []
    }
  },
  created () {
    this.$api.getExperts().then(res => {
      this.expertsList = res.experts;
    })
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    toProduction (index) {
      this.$router.push({
        name: 'personCenter',
        params: {
          from: 'IDC',
          type: index
        }
      })
    }
  },
  components: {
    CommonFooter,
    CommonActivies
  }
}
</script>
